import { template as template_3a65925ee20243e08285c90bd7fe2059 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_3a65925ee20243e08285c90bd7fe2059(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
