import { template as template_00bb21cd280249ba872592d00aa9d07c } from "@ember/template-compiler";
const FKControlMenuContainer = template_00bb21cd280249ba872592d00aa9d07c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
