import { template as template_eac8aa351bcb44af8a4e57f3f4d3a964 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_eac8aa351bcb44af8a4e57f3f4d3a964(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_eac8aa351bcb44af8a4e57f3f4d3a964(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_eac8aa351bcb44af8a4e57f3f4d3a964(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
