import { template as template_f40b44a9f5ed455e964a2f31a9d27c2c } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuShareButton = template_f40b44a9f5ed455e964a2f31a9d27c2c(`
  <DButton
    class="post-action-menu__share share"
    ...attributes
    @action={{@buttonActions.share}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.share_action"}}
    @title="post.controls.share"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuShareButton;
