import { template as template_2be8d100f29c4bd8a47ce9bb3eded21a } from "@ember/template-compiler";
const WelcomeHeader = template_2be8d100f29c4bd8a47ce9bb3eded21a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
