import { template as template_12592b0c17ed415aabbe593813bfe06f } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_12592b0c17ed415aabbe593813bfe06f(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
